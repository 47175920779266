<script>
    import Layout from "@/views/layouts/static/main";
    import PageHeader from "@/components/static/page-header";
    import appConfig from "@/app.config";
    import Swal from 'sweetalert2';



    /**
     * Add-product component
     */
    export default {
        page: {
            title: "Managers",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        components: {
            Layout,
            PageHeader,
          
        },
   
        data() {
            return {
             
                title: "Managers",
                managers:[],
                locations: [],
                location_id:"showAll",
                items: [
                    {
                        text: "Dashboard",
                    },
                    {
                        text: "Managers",
                        active: true,
                    },
                ],

                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "age",
                sortDesc: false,
                  search:'',
                 table_data : {
                        draw: 0,
                        length: 10,
                        search:'',
                        paginated:true
                        
            },
                fields: [
                    
                       {
                        key: "name",
                        label: "Name",
                        sortable: true,
                    },
                       {
                        key: "email",
                        label: "Email",
                        sortable: true,
                    },
                       {
                        key: "phone",
                        label: "Phone",
                        sortable: true,
                    },
                     
                     
                        {
                        key: "created_at",
                        label: "Created At",
                        sortable: true,
                        formatter:(key) => {
                            return this.DateWithMonthName(key , 'ddd, MMM DD,YYYY hh:mm A')
                        },
                    },
                    "location_name",
                    "action",
                ],
                loading:false,
            };
        },

  watch:{
     currentPage:{
         handler:function(currentPage,pre_currentPage){
             if(currentPage>pre_currentPage){
                 this.table_data.length+=this.perPage
                 this.fetchManagers();
             }
         }
     },
     search:{
         handler:function(search,pre_search){
                 this.table_data.search=search
                 this.fetchManagers();
             
         }
     },
     location_id:{
         handler:function(new_id,pre_id){
                if(new_id!='showAll'){
                    this.table_data.location_id=new_id
                    this.fetchManagers();
                }else{
                    this.table_data.location_id=null
                    this.fetchManagers();
                    
                }
             
         }
     },
 },

  mounted() {
    this.fetchManagers();
    this.fetchLocations();
    this.totalRows = this.items.length;
  },
  methods: {
    fetchLocations(){
        this.$axios.get('/restaurant/location/index').then(response => {
            this.locations = response.data.payload;
        }).catch(error => {
        }).finally(function(){
        });
    },
      fetchManagers(url) {
        url = url ?? '/employee/manager/index';
         this.loading = true;
          this.table_data.draw++;
            this.$axios.get(url,{params: this.table_data})
                .then(response => {
                    let data = response.data.payload;
                    this.managers=data.paginated;
                    this.loading = false                
                }).catch(error => {
                    this.handleErrorResponse(error.response, "error");
                }).finally(() => (this.loading = false));   
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    getLocation(locations){
        let allLocations = this.currentUser.restaurant.location;
        let location_name_array= [];
        locations.forEach((location) => {
            allLocations.forEach(loc_id => {   
                if (loc_id.id == location.id) {
                    location_name_array.push(loc_id.name);
                }
            });
        });
        return location_name_array.toString();
    },
    deleteManager(id){
      Swal.fire({
        title: "Are you sure?",
        text: "Please confirm that you understand what will happen by deleting user. All the data related to this user will be permanently deleted from the system and this cannot be undone.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
              this.$axios.delete(`admin/delete/${id}`)
                .then(response => {
                    let data = response.data;
                
                    this.managers.data = this.managers.data.filter((item) => (item.id != id));
                                     
                    Swal.fire("Deleted!", data.message, "success");
                }).catch(error => {
                    this.handleErrorResponse(error.response, "error");
                }).finally(function(){
                });
        }
      }); 
    },

  },
        middleware: "authentication",
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-12 text-end">
                        <router-link to="/create-team-member" >
                            <b-button class="gradientColor border-0 radius-20 px-4 float-end"> Add Manager </b-button>
                        </router-link>
                    </div>
                    <div class="col-md-4">
                    <label>Filter by Location</label>                           
                        <select v-model="location_id"   id="emp_job_type" class="form-select">
                            <option value="showAll" >Show All</option>
                            <option :value="location.id" v-for="(location,index) in locations" :key="index" >{{location.name}}</option>
                        </select>
                    </div>

                    <div class="col-md-4 mx-auto me-0">
                        <b-form-group id="input-group-2" label="Search" label-for="input-2">
                            <b-form-input id="input-2" v-model="search" placeholder="Search" ></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="mb-0">
                        <div>
                            <div>
                                <div class="table-responsive mb-0 mt-3 table-nowrap">
                                    <b-table
                                        table-class="table table-centered datatable table-bordered"
                                        thead-tr-class="bg-transparent"
                                        :items="managers.data"
                                        :fields="fields"
                                        responsive="sm"
                                        :per-page="perPage"
                                        :current-page="currentPage"
                                        :sort-by.sync="sortBy"
                                        :sort-desc.sync="sortDesc"
                                        :filter="filter"
                                        :filter-included-fields="filterOn"
                                        @filtered="onFiltered"
                                        :busy="loading"
                                        show-empty
                                    >
                                        <template #table-busy>
                                            <APILoader v-if="loading" :loading="loading" class="align-middle"></APILoader>
                                        </template>
                                        <template v-slot:cell(location_name)="data">
                                            {{ getLocation(data.item.employee.locations)}}
                                            
                                        </template>
                                        
                                        <template v-slot:cell(action)="data">
                                            <ul class="d-flex list-inline mb-0">
                                                <li class="list-inline-item">
                                                    
                                                    <router-link v-b-tooltip.hover title="Edit" :to="{ name: 'edit_profile_manager', params: { user_id: data.item.id }}">
                                                            <i class="uil uil-pen font-size-18"></i>
                                                    </router-link>
                                                </li>
                                                <li class="list-inline-item">
                                                    <a href="#" class="px-2 text-danger" v-b-tooltip.hover @click="deleteManager(data.item.id)" title="Delete">
                                                        <i class="uil uil-trash-alt font-size-18"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </template>
                                    </b-table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="dataTables_paginate paging_simple_numbers float-end">
                        <ul class="pagination pagination-rounded">
                            <div class="col-sm-12 col-md-12 mt-3">
                            <div class="dataTables_paginate paging_simple_numbers">
                                <ul class="pagination justify-content-end py-0 my-0 gap-2">
                                <li
                                    class="paginate_button page-item previous"
                                    :class="managers.prev_page_url == null ? 'disabled' : ''"
                                >
                                    <button
                                    type="button"
                                    :class="managers.prev_page_url == null ? 'disabled' : ''"
                                    @click="fetchManagers(managers.prev_page_url)"
                                    class="btn bg-light text-dark"
                                    >
                                    <i class="fas fa-arrow-left"></i>
                                    </button>
                                </li>

                                <li
                                    class="paginate_button page-item next"
                                    :class="managers.next_page_url == null ? 'disabled' : ''"
                                >
                                    <button
                                    type="button"
                                    :class="managers.next_page_url == null ? 'disabled' : ''"
                                    @click="fetchManagers(managers.next_page_url)"
                                    aria-controls="DataTables_Table_0"
                                    data-dt-idx="8"
                                    tabindex="0"
                                    class="btn bg-light text-dark"
                                    >
                                    <i class="fas fa-arrow-right"></i>
                                    </button>
                                </li>
                                </ul>
                            </div>
                            </div>
                        </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
